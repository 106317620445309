<template>
  <searchable-table
    :items="items"
    :fields="fields"
  >
    <template #actions>
      <div class="d-flex gap-5">
        <app-button
          text="Öde"
          @click="$router.push({ name: 'customer-payment' })"
        />
        <app-button text="Görüntüle" />
      </div>
    </template>
  </searchable-table>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'invoiceDate', label: 'Fatura Tarihi', sortable: true, formattedDateTime: true,
        },
        { key: 'dueDate', label: 'Son Ödeme Tarihi', sortable: true },
        {
          key: 'amount', label: 'Tutar', sortable: true, addition: '₺',
        },
        { key: 'actions', label: 'Eylemler', sortable: false },
      ],
    }
  },
}
</script>
